html,
body,
#root,
.ant-app,
.app-layout {
  height: 100%;
}

.emoji {
  font-family: 'Noto Color Emoji', sans-serif;
}

.hint {
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dashed;
}

button.ant-btn {
  text-transform: uppercase;
}

button.ant-btn-link {
  text-transform: none;
}

form#register div.ant-form-item {
  margin-bottom: 12px;
}

.language-select .ant-select-selector {
  height: 34px !important;
}

.header-menu-item {
  font-family: 'Aviano Sans W00 Regular';
  color: #2d2d2d;
}

.header-menu-item:hover,
.header-menu-item.active {
  color: #b85632;
  border-bottom: 1px solid #b85632;
}

.header-menu-item.disabled {
  color: #bfbfbf;
}

.header-menu-item.disabled:hover {
  color: #bfbfbf;
  border-bottom: unset;
}

.login-form {
  height: calc(100% - 38px - 15px);
}

.ant-table .ant-table-row:nth-child(even) > td {
  background-color: #fcfcfc;
}

.ant-table thead.ant-table-thead td {
  text-align: center;
}

/** used to force input on same line on forms with layout=vertical */
.ant-form-vertical
  .ant-form-item.force-horizontal
  > .ant-row.ant-form-item-row {
  flex-flow: row nowrap;
  column-gap: 12px;
}

.ant-form-vertical .ant-form-item.force-horizontal .ant-form-item-control {
  width: unset;
}

.ant-form-vertical
  .ant-form-item.force-horizontal
  .ant-form-item-control-input {
  align-items: start;
}

/** to only align Table column's titles (and not the content) */
.ant-table-wrapper .ant-table-thead > tr > th.align-title-center {
  text-align: center !important;
}

.ant-form-item.ant-form-item-with-help.help-text-green .ant-form-item-explain {
  color: #52c41a;
}

iframe.full-size {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
}
