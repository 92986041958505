@font-face {
  font-family: 'Aviano Sans W00 Regular';
  src: url('fonts/aviano/97f6a283-0d6a-44ad-81cd-6c6e3b422538.woff2')
      format('woff2'),
    url('fonts/aviano/0627986f-e30f-40b8-9797-6bb659e736a1.woff') format('woff');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
